// DataCenter.js

import cookies from 'js-cookie'

// 싱글톤 인스턴스를 저장할 변수
let instance = null

/**
 * 데이터 센터 키
 */
// 시스템 데이터 키값
const KEYS_SYSTEM = {
    APP_TYPE: 'SYSTEM_APP_TYPE', // 앱 타입 'Android' : 안드로이드, 'IOS' : 아이폰
    APP_VERSION: 'SYSTEM_APP_VERSION', // 앱 버전 '1.0.0'
    APP_OS_VERSION: 'SYSTEM_APP_OS_VERSION', // 앱 OS 버전 '14.0.0'
    LANGUAGE_CODE: 'SYSTEM_LANGUAGE_CODE', // 언어 'ko' : 한국어, 'en' : 영어
    COUNTRY_CODE: 'SYSTEM_COUNTRY_CODE', // 국가 '82' : 한국, '1' : 미국
    UTC_INTERVAL: 'SYSTEM_UTC_INTERVAL', // UTC 시간차 -540 : 한국, -420 : 미국
    CAMERA_PERMISSION: 'SYSTEM_CAMERA_PERMISSION', // 카메라 권한 허용 여부 'NOT_YET', 'GRANTED' : 허용, 'DENIED' : 비허용
    PUSH_PERMISSION: 'SYSTEM_PUSH_PERMISSION', // 푸시 권한 허용 여부 'NOT_YET', 'GRANTED' : 허용, 'DENIED' : 비허용
}

// 사용자
const KEYS_USERINFO = {
    IS_ADMIN: 'USERINFO_IS_ADMIN', // 어드민 여부 'Y' : 어드민, 'N' : 일반 사용자
    HP: 'USERINFO_HP', // 핸드폰 번호
    UID: 'USERINFO_UID', // 사용자 고유번호
    ENCRYPT_ID: 'USERINFO_ENCRYPT_ID', // 사용자 암호화 아이디
    STORE_ID: 'USERINFO_STORE_ID', // 가맹점 아이디
}
// ETC
const KEYS_ETC = {
    TEMP_KEY_1: 'TEMP_KEY_1',
    AGREEMENT_NUMBER: 'AGREEMENT_NUMBER',
}
// RECORD
const KEYS_RECORD = {
    TEMP_MEAL_RECORD_DATA: 'TEMP_MEAL_RECORD_DATA',
}

/**
 * 데이터 센터 값
 */
// IS 종류(YES or NO) 키값의 값, ex. IS_ADMIN
const VALUES_YN = {
    YES: 'Y',
    NO: 'N',
}

// OS 종류 키값의 값, ex. APP_TYPE
const VALUES_OS_TYPE = {
    IOS: 'IOS',
    AOS: 'Android',
}

// PERMISSION 종류 키값의 값, ex. CAMERA_PERMISSION PUSH_PERMISSION
const VALUES_PERMISSION = {
    NOT_YET: 'NOT_YET',
    GRANTED: 'GRANTED',
    DENIED: 'DENIED',
}

// MAIN 새로고침 요청 타입
const VALUES_MAIN_REFRESH_TYPE = {
    NEW_MISSION: 'NEWMISSION',
}

/**
 * 함수
 */

/**
 * 유효기간 1년으로 쿠키값 저장
 * @param { String } key
 * @param { * } value
 */
function setCookieForYear(key, value) {
    // App 정보는 만료시간 360일 후 설정
    const expAppInfo = new Date()
    expAppInfo.setTime(expAppInfo.getTime() + 1000 * 60 * 60 * 24 * 30 * 12)
    cookies.set(key, value, { expires: expAppInfo })
}

/**
 * 유효기간 x일으로 쿠키값 저장
 * @param { String } key
 * @param { * } value
 */
function setCookieForDays(key, value, day) {
    // App 정보는 만료시간 day일 후 설정
    const expAppInfo = new Date()
    expAppInfo.setTime(expAppInfo.getTime() + 1000 * 60 * 60 * 24 * day)
    cookies.set(key, value, { expires: expAppInfo })
}

/**
 * 유효기간 1일으로 쿠키값 저장
 * @param { String } key
 * @param { * } value
 */
function setCookieForDay(key, value) {
    // App 정보는 만료시간 1일 후 설정
    const expAppInfo = new Date()
    expAppInfo.setTime(expAppInfo.getTime() + 1000 * 60 * 60 * 24)
    cookies.set(key, value, { expires: expAppInfo })
}

/**
 * key값에 해당하는 쿠키값을 가져온다.
 * @param { String } key KEYS 객체의 키값
 * @returns
 */
function getCookie(key) {
    return cookies.get(key)
}

/**
 * key값에 해당하는 쿠키값을 삭제한다.
 * @param { String } key KEYS 객체의 키값
 */
function deleteCookie(key) {
    cookies.remove(key)
}

function setLocalStorageData(key, value) {
    window.localStorage.setItem(key, value)
}

function getLocalStorageData(key) {
    return window.localStorage.getItem(key)
}

function deleteLocalStorageData(key) {
    window.localStorage.removeItem(key)
}

export function useDataCenter() {
    if (!instance) {
        instance = {
            KEYS_SYSTEM,
            KEYS_USERINFO,
            VALUES_PERMISSION,
            VALUES_OS_TYPE,
            VALUES_YN,
            VALUES_MAIN_REFRESH_TYPE,
            KEYS_ETC,
            KEYS_RECORD,
            setCookieForYear,
            setCookieForDays,
            setCookieForDay,
            getCookie,
            deleteCookie,
            setLocalStorageData,
            getLocalStorageData,
            deleteLocalStorageData,
        }
    }

    return instance
}
