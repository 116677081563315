import {
  createRouter,
  createWebHistory
} from 'vue-router'

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: () => import('@/components/HelloWorld.vue')
  // }
  {
    path: '/',
    name: 'MainPage',
    component: () => import('../components/MainPage.vue')
  },
  {
    path: '/LoginPage',
    name: 'LoginPage',
    component: () => import('../components/login/LoginPage.vue')
  },
  {
    path: '/SetupPage',
    name: 'SetupPage',
    component: () => import('../components/setup/SetupPage.vue')
  },
  {
    path: '/AttendancePage',
    name: 'AttendancePage',
    component: () => import('../components/attendance/AttendancePage.vue')
  },
  {
    path: '/RewardHistoryPage',
    name: 'RewardHistoryPage',
    component: () => import('../components/reward/RewardHistoryPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router