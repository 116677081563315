import { useDataCenter } from './dataCenter'
import { historyPage } from '@/utils/utils'

const dataCenter = useDataCenter()

// 안드로이드 뒤로가기 버튼 액션
let isRecordPage = false
export function SetIsRecordPage(isRecord) {
    isRecordPage = isRecord
}

export function AppCallBackAction() {
    if (!isRecordPage) historyPage(false)
}

// 푸시 권한 업데이트
let handlerPushPermission = null
export function SetHandlerPushPermission(handler) {
    handlerPushPermission = handler
}
export function UpdatePushPermission(status) {
    dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.PUSH_PERMISSION, status)

    if (handlerPushPermission) {
        handlerPushPermission()
    }
}

// 카메라 권한 업데이트
export function UpdateCameraPermission(status) {
    dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.CAMERA_PERMISSION, status)
}

// 이미지, 시간 데이터 (식단&운동 등록 페이지와 프로필 설정에서 사용)
let handlerImageData = null
export function SetHandlerImageData(handler) {
    handlerImageData = handler
}
export function SetImageData(Base64, timeStr) {
    if (handlerImageData) {
        handlerImageData(Base64, timeStr)
    }
    // HH:mm (분은 5분 단위로)
}

// 권한 안내 팝업 노출
let handlerPermissionGuide = null
export function SetHandlerPermissionGuide(handler) {
    handlerPermissionGuide = handler
}
export function ShowModalPermissionGuide() {
    if (handlerPermissionGuide) {
        handlerPermissionGuide()
    }
}

// 웹뷰 강제종료 이벤트 (모바일에서 웹뷰가 강제로 종료됐을 때 상황처리)
let handlerWebViewForceClosed = null
export function SetHandlerWebViewForceClosed(handler) {
    handlerWebViewForceClosed = handler
}
export function WebViewForceClosed() {
    if (handlerWebViewForceClosed) {
        handlerWebViewForceClosed()
    }
}

// 푸시 정보 전달 (사용자가 푸시를 클릭한 경우)
let handlerPushInfo = null
export function SetHandlerPushInfo(handler) {
    handlerPushInfo = handler
}
/*
    data = {
        ViewCode: 0,
        LandingCode: '',
        LandingData: ''
 */
export function SetPushInfo(viewCode, landingCode, landingData) {
    if (handlerPushInfo) {
        handlerPushInfo({
            ViewCode: viewCode,
            LandingCode: landingCode,
            LandingData: landingData,
        })
    }
}

// 웹뷰 알림 페이지 이동
let handlerViewNotificationPage = null
export function SetHandlerViewNotificationPage(handler) {
    handlerViewNotificationPage = handler
}
export function ViewNotificationPage() {
    if (handlerViewNotificationPage) {
        handlerViewNotificationPage()
    }
}

// 웹뷰 특정 페이지로 이동
let handlerViewSpecificPageWithPath = null
export function SetHandlerViewSpecificPageWithPath(handler) {
    handlerViewSpecificPageWithPath = handler
}
export function ViewSpecificPageWithPath(path) {
    if (handlerViewSpecificPageWithPath) {
        handlerViewSpecificPageWithPath(path)
    }
}

// 새로고침 요청
let handlerPageRefresh = null
export function SetHandlerPageRefresh(handler) {
    handlerPageRefresh = handler
}
export function PageRefresh() {
    if (handlerPageRefresh) {
        handlerPageRefresh()
    }
}
