<template>
    <div>
        <router-view />
    </div>
</template>

<script setup>
import config from '@/config'
import { useDataCenter } from '@/managers/dataCenter'
import {
    SetHandlerPushPermission,
    UpdatePushPermission,
    UpdateCameraPermission,
    AppCallBackAction,
    SetHandlerViewSpecificPageWithPath,
    ViewSpecificPageWithPath,
    SetHandlerPushInfo,
    SetPushInfo,
} from '@/managers/publicMethod'
import { onBeforeMount, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const dataCenter = useDataCenter()
const { t } = useI18n()
const router = useRouter()

// 웹뷰 특정페이지로 이동  
const handlerViewSpecificPageWithPath = (path) => {
    router.push(path)
}

// 푸시 랜딩 정보 처리
const handlerSetPushInfo = (data) => {
    console.log('handlerSetPushInfo')
    console.log(data.ViewCode + ' / ' + data.LandingCode + ' / ' + data.LandingData)

    // 푸시 랜딩 정보 처리
    if (data.ViewCode == '1') {
        if (data.LandingCode == 'SUNNYUP_PAGE_LANDING') {
            // 푸시 랜딩 페이지로 이동
            router.push(data.LandingData)
        }
    }
}

onBeforeMount(() => {
    if (config.isLocalDevelopment) {
        // 임시 사용자 정보데이터
        // setCookieForYear('UID', '2701858_NoEqupiMB') // 1981_NoEqupiMB(01000001023) / 2002_NoEqupiMB(01000001029)
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.APP_TYPE, 'IOS')
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.APP_VERSION, '2.4.06')
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.APP_OS_VERSION, '1.0')
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.LANGUAGE_CODE, 'ko')
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.COUNTRY_CODE, '82')
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.UTC_INTERVAL, -540)
    }

    const urlParams = new URLSearchParams(window.location.search)
    // let uid = urlParams.get('uId')
    // if (uid !== undefined && uid !== null) setCookieForYear('UID', uid)
    let appType = urlParams.get('appType')
    if (appType !== undefined && appType !== null) dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.APP_TYPE, appType)
    let appVersion = urlParams.get('appVersion')
    if (appVersion !== undefined && appVersion !== null)
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.APP_VERSION, appVersion)
    let osVersion = urlParams.get('osVersion')
    if (osVersion !== undefined && osVersion !== null)
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.APP_OS_VERSION, osVersion)
    let language = urlParams.get('language')
    if (language !== undefined && language !== null)
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.LANGUAGE_CODE, language)
    let country = urlParams.get('country')
    if (country !== undefined && country !== null)
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.COUNTRY_CODE, country)

    let languageCode = dataCenter.getCookie(dataCenter.KEYS_SYSTEM.LANGUAGE_CODE)
    t.locale = languageCode === undefined ? 'ko' : languageCode

    let utcInterval = dataCenter.getCookie(dataCenter.KEYS_SYSTEM.UTC_INTERVAL)
    if (utcInterval === undefined || utcInterval === null) {
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.UTC_INTERVAL, -540)
    }

    // 웹뷰 생성시 사용자 정보 조회
    let encryptId = dataCenter.getCookie(dataCenter.KEYS_USERINFO.ENCRYPT_ID)
    if (encryptId !== undefined && encryptId !== null) {
        // 사용자 EncryptID 정보 재저장(쿠키만료기간갱신)
        dataCenter.setCookieForYear(dataCenter.KEYS_USERINFO.ENCRYPT_ID, encryptId)
    }
})
onMounted(() => {
    SetHandlerPushPermission(null)
    window.UpdatePushPermission = UpdatePushPermission
    window.UpdateCameraPermission = UpdateCameraPermission
    window.AppCallBackAction = AppCallBackAction
    SetHandlerViewSpecificPageWithPath(handlerViewSpecificPageWithPath)
    window.ViewSpecificPageWithPath = ViewSpecificPageWithPath
    SetHandlerPushInfo(handlerSetPushInfo)
    window.SetPushInfo = SetPushInfo
})
</script>
//
<style>
@import './assets/css/normalization.css';
@import './assets/css/iconImage.css';

/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}
</style>
